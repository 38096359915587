import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import ClientsByJobsV2 from "../../components/analytics/ClientsByJobsV2";
import SelectJobComboBox from "../../components/analytics/SelectJobComboBox";
import { getJobs } from "../../services/jobs/getJobs";

const JobsReportV2 = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState({ name: null });

    useEffect(() => {
        const getNewJobs = async () => {
            const response = await getJobs();
            if (response.success) {
                console.log(response);
                setJobs(response.data.jobs);
            }
        };
        getNewJobs();
    }, []);

    const onChangeService = (newJob) => {
        console.log(newJob);
        setSelectedJob(newJob);
    };
    return (
        <section>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                    <main>
                        <div className="w-full px-4 py-8 mx-auto sm:px-6 lg:px-8 max-w-9xl">
                            {/* Page header */}
                            <div className="mb-8 sm:flex sm:justify-between sm:items-center">
                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0 text">
                                    <h1 className="text-2xl font-bold md:text-3xl text-slate-800">
                                        Pipeline Report
                                    </h1>
                                    <ul>
                                        {selectedJob.name !== null && (
                                            <li>{selectedJob.name}</li>
                                        )}
                                    </ul>
                                </div>
                                <div className="flex flex-col items-center sm:flex-row sm:space-x-2">
                                    <div className="flex flex-col items-start">
                                        <SelectJobComboBox
                                            label="Select pipeline"
                                            items={jobs}
                                            selectedItem={selectedJob}
                                            onChangeItem={onChangeService}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedJob.name !== null ? (
                            <ClientsByJobsV2 task={selectedJob} />
                        ) : (
                            <h4 className="m-8 font-bold">
                                Please select a job to see the report.
                            </h4>
                        )}
                    </main>
                </div>
            </div>
        </section>
    );
};
export default JobsReportV2;
