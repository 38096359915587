import { useMemo } from "react";
import FirmFlowTable from "../../../../components/mytasks/FirmFlowTable"; // Adjust the import path as necessary

const EmployeeWorkloadTable = ({ data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Employee",
                accessor: "employeeName",
                Cell: (props) => props.value,
            },
            {
                Header: "Total Clients",
                accessor: "totalClients",
                Cell: (props) => {
                    return (
                        <a
                            href={`/filteredClients?employeeId=${props.row.original.employeeId}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-500 hover:underline"
                        >
                            {props.value}
                        </a>
                    );
                },
            },
        ],
        []
    );
    return (
        <div>
            <FirmFlowTable columns={columns} data={data} />
        </div>
    );
};

export default EmployeeWorkloadTable;
