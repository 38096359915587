import React, { useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Header from "../../../components/Header";
import FirmFlowTable from "../../../components/mytasks/FirmFlowTable";
import Sidebar from "../../../components/Sidebar";
import { getFilteredClients } from "../../../services/analytics/getFilteredClients";

function FilteredClients() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [name, setName] = useState("");
    const [totalClients, setTotalClients] = useState(0);
    const [tooltip, showTooltip] = useState(true);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const employeeId = searchParams.get("employeeId");
    const departmentId = searchParams.get("departmentId");
    const teamId = searchParams.get("teamId");

    useEffect(() => {
        console.log(employeeId, departmentId, teamId);
        const fetchClients = async () => {
            const response = await getFilteredClients({
                employeeId: employeeId,
                departmentId: departmentId,
                teamId: teamId,
            });
            if (response.success) {
                setClients(response.data.clients);
                setTotalClients(response.data.totalClients);
                if (employeeId) {
                    setName(response.data.employeeName);
                }
                if (departmentId) {
                    setName(response.data.departmentName);
                }
                if (teamId) {
                    setName(response.data.teamName);
                }
            } else {
                toast.error("Failed to fetch clients");
            }
            console.log(response);
        };
        fetchClients();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Client",
                accessor: "clientName",
                Cell: (props) => (
                    <>
                        {tooltip && (
                            <ReactTooltip
                                type="light"
                                effect="solid"
                                place="top"
                                border={true}
                                borderColor={"rgb(220,220,220)"}
                                className="rounded-md bg-gray-50"
                                multiline={false}
                            />
                        )}
                        <button
                            onMouseEnter={() => showTooltip(true)}
                            onMouseLeave={() => showTooltip(false)}
                            onClick={() =>
                                navigate(
                                    `/clients/manage/${props.row.original.clientId}`
                                )
                            }
                            className="rounded-full text-gray hover:text-blue-500"
                        >
                            {props.row.original.clientName}
                        </button>
                    </>
                ),
            },
        ],
        []
    );

    return (
        <div className="flex h-screen overflow-hidden">
            <Toaster />
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="w-full px-4 py-8 mx-auto sm:px-6 lg:px-8 max-w-9xl">
                        {/* Page header */}
                        <div className="mb-8 sm:flex sm:justify-between sm:items-center">
                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl font-bold md:text-3xl text-slate-800">
                                    {totalClients} Clients assigned to{" "}
                                    {employeeId && `Employee: ${name}`}{" "}
                                    {departmentId && `Department: ${name}`}{" "}
                                    {teamId && `Team: ${name}`}
                                </h1>
                            </div>
                        </div>

                        {/* Table */}
                        <FirmFlowTable data={clients} columns={columns} />
                    </div>
                </main>
            </div>
        </div>
    );
}

export default FilteredClients;
