import { useMemo } from "react";
import FirmFlowTable from "../../../../components/mytasks/FirmFlowTable"; // Adjust the import path as necessary

const WorkloadReportByDepartments = ({ data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Department",
                accessor: "departmentName",
                Cell: (props) => props.value,
            },
            {
                Header: "Teams",
                accessor: "teams",
                Cell: (props) => {
                    return props.value.length > 0
                        ? props.value.map((team) => (
                              <ul key={team.teamId}>
                                  <li className="list-disc">{team.teamName}</li>
                              </ul>
                          ))
                        : "No Teams";
                },
            },
            {
                Header: "Total Clients",
                accessor: "totalClients",
                Cell: (props) => {
                    return (
                        <a
                            href={`/filteredClients?departmentId=${props.row.original.departmentId}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-500 hover:underline"
                        >
                            {props.value}
                        </a>
                    );
                },
            },
        ],
        []
    );
    return (
        <div>
            <FirmFlowTable columns={columns} data={data} />
        </div>
    );
};

export default WorkloadReportByDepartments;
