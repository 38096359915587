import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import { getEmployeesWorkloadReport } from "../../../services/analytics/getEmployeesWorkload";
import EmployeeWorkloadTable from "./components/EmployeeWorkloadTable";
import { getEmployees } from "../../../services/getEmployees";
import Select from "react-select";
import { getServiceDepartments } from "../../../services/service/getServiceDepartments";
import { getTeams } from "../../../services/teams/getTeams";
import { getWorkloadByTeams } from "../../../services/analytics/getWorkloadByTeams";
import WorkloadReportByTeams from "./components/WorkloadReportByTeams";
import { getWorkloadByDepartments } from "../../../services/analytics/getWorkloadByDepartments";
import WorkloadReportByDepartments from "./components/WorkloadReportByDepartments";

const SELECT_INITIALIZATION = [
    {
        value: "00000000-0000-0000-0000-000000000000",
        label: "All",
        is_showing: true,
    },
];
const WorkloadReport = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [clientsByEmployee, setClientsByEmployee] = useState([]);
    const [clientsByTeam, setClientsByTeam] = useState([]);
    const [employees, setEmployees] = useState(SELECT_INITIALIZATION);
    const [departments, setDepartments] = useState(SELECT_INITIALIZATION);
    const [teams, setTeams] = useState(SELECT_INITIALIZATION);
    const [selectedEmployee, setSelectedEmployee] = useState(employees[0]);
    const [selectedDepartment, setSelectedDepartment] = useState(departments[0]);
    const [selectedTeam, setSelectedTeam] = useState(teams[0]);
    const [clientsByDepartment, setClientsByDepartment] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("employees");

    useEffect(() => {
        fetchEmployees();
        fetchDepartments();
        fetchTeams();
    }, []);

    useEffect(() => {
        if (selectedGroup === "employees") {
            fetchReportByEmployees();
        }
        if (selectedGroup === "teams") {
            fetchReportByTeams();
        }
        if (selectedGroup === "departments") {
            fetchReportByDepartments();
        }
    }, [selectedEmployee, selectedDepartment, selectedTeam]);

    async function fetchEmployees() {
        Promise.all([getEmployees()]).then((res) => {
            let data = res[0].data.employees;
            console.log(data);
            const arr = [];
            // eslint-disable-next-line
            data.map((emp, i) => {
                arr.push({
                    value: emp.employeeId,
                    label: `${emp.firstName} ${emp.lastName}`,
                    is_showing: true,
                });
                if (arr.length > 0) {
                    // setEmployee(arr[0]);
                    let tmp = [employees[0], ...arr];
                    // console.log(tmp);
                    setEmployees(tmp);
                }
            });
            // console.log(arr);
        });
    }

    const fetchDepartments = async () => {
        const response = await getServiceDepartments();
        const departmentsArray = [];
        if (response.data.success) {
            response.data.departments.map((department) => {
                departmentsArray.push({
                    value: department.id,
                    label: department.name,
                    is_showing: true,
                });
            });
            setDepartments([departments[0], ...departmentsArray]);
        }
        console.log(response);
    };

    const fetchTeams = async () => {
        const response = await getTeams();
        const teamsArray = [];
        if (response.data.success) {
            response.data.teams.map((team) => {
                teamsArray.push({
                    value: team.id,
                    label: team.name,
                    is_showing: true,
                });
            });
            setTeams([teams[0], ...teamsArray]);
        }
        console.log(response);
    };

    const fetchReportByEmployees = async () => {
        const response = await getEmployeesWorkloadReport({
            employeeId: selectedEmployee.value,
            departmentId: selectedDepartment.value,
            teamId: selectedTeam.value,
        });
        if (response.data.success) {
            setClientsByEmployee(response.data.employeesWorkload);
            console.log(response.data.employeesWorkload);
        } else {
            console.log(response);
        }
    };

    const fetchReportByTeams = async () => {
        const response = await getWorkloadByTeams({
            employeeId: selectedEmployee.value,
            departmentId: selectedDepartment.value,
            teamId: selectedTeam.value,
        });
        if (response.data.success) {
            setClientsByTeam(response.data.workloadByTeams);
            console.log(response.data.workloadByTeams);
        } else {
            console.log(response);
        }
    };

    const fetchReportByDepartments = async () => {
        const response = await getWorkloadByDepartments({
            employeeId: selectedEmployee.value,
            departmentId: selectedDepartment.value,
            teamId: selectedTeam.value,
        });
        if (response.data.success) {
            setClientsByDepartment(response.data.workloadByDepartments);
            console.log(response.data.workloadByDepartments);
        } else {
            console.log(response);
        }
    };

    const onChangEmployee = (e) => {
        setSelectedEmployee(e);
    };

    const onChangeDepartment = (e) => {
        setSelectedDepartment(e);
    };

    const onChangeTeam = (e) => {
        setSelectedTeam(e);
    };

    const onChangeGroup = (group) => {
        /*  setSelectedDepartment(departments[0]);
        setSelectedEmployee(employees[0]);
        setSelectedTeam(teams[0]); */
        if (group === "employees") {
            fetchReportByEmployees();
        }
        if (group === "teams") {
            fetchReportByTeams();
        }
        if (group === "departments") {
            fetchReportByDepartments();
        }
        setSelectedGroup(group);
    };

    return (
        <section>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="w-full px-4 py-8 mx-auto sm:px-6 lg:px-8 max-w-9xl">
                            {/* Page header */}
                            <div className="mb-8 sm:flex sm:justify-between sm:items-center">
                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl font-bold md:text-3xl text-slate-800">
                                        Workload Report
                                    </h1>
                                </div>

                                <div className="flex flex-col items-start lg:space-x-2 lg:flex-row">
                                    <div className="flex flex-col items-center sm:flex-row sm:space-x-2">
                                        <div className="flex flex-col items-start">
                                            <div className="flex flex-col items-center w-full p-4 bg-white rounded-lg shadow-lg">
                                                <h2 className="pb-6 font-bold text-center text-1xl md:text-1xl text-slate-800">
                                                    View by
                                                </h2>

                                                <div className="flex space-x-2">
                                                    <button
                                                        className={`px-4 py-2 rounded ${
                                                            selectedGroup ===
                                                            "employees"
                                                                ? "bg-teal-500 text-white"
                                                                : "bg-gray-200 text-gray-800"
                                                        }`}
                                                        onClick={() =>
                                                            onChangeGroup(
                                                                "employees"
                                                            )
                                                        }
                                                    >
                                                        Employees
                                                    </button>
                                                    <button
                                                        className={`px-4 py-2 rounded ${
                                                            selectedGroup === "teams"
                                                                ? "bg-teal-500 text-white"
                                                                : "bg-gray-200 text-gray-800"
                                                        }`}
                                                        onClick={() =>
                                                            onChangeGroup("teams")
                                                        }
                                                    >
                                                        Teams
                                                    </button>
                                                    <button
                                                        className={`px-4 py-2 rounded ${
                                                            selectedGroup ===
                                                            "departments"
                                                                ? "bg-teal-500 text-white"
                                                                : "bg-gray-200 text-gray-800"
                                                        }`}
                                                        onClick={() =>
                                                            onChangeGroup(
                                                                "departments"
                                                            )
                                                        }
                                                    >
                                                        Departments
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-lg">
                                            <div className="w-full font-bold text-center text-1xl text-slate-800">
                                                Filter by
                                            </div>
                                            <div className="flex items-start justify-between w-full space-x-2">
                                                <div className="flex flex-col items-start">
                                                    <h2 className="font-bold text-1xl md:text-1xl text-slate-800">
                                                        Select Employee
                                                    </h2>
                                                    {employees.length > 0 && (
                                                        <Select
                                                            onChange={
                                                                onChangEmployee
                                                            }
                                                            className="basic-single min-w-[180px] w-auto"
                                                            classNamePrefix="select"
                                                            isSearchable={true}
                                                            defaultValue={
                                                                employees[0]
                                                            }
                                                            options={employees}
                                                            name="employees"
                                                        />
                                                    )}
                                                </div>
                                                <div className="flex flex-col items-start">
                                                    <h2 className="font-bold text-1xl md:text-1xl text-slate-800">
                                                        Select Department
                                                    </h2>
                                                    {departments.length > 0 && (
                                                        <Select
                                                            onChange={
                                                                onChangeDepartment
                                                            }
                                                            className="basic-single min-w-[180px] w-auto"
                                                            classNamePrefix="select"
                                                            isSearchable={true}
                                                            defaultValue={
                                                                departments[0]
                                                            }
                                                            options={departments}
                                                            name="departments"
                                                        />
                                                    )}
                                                </div>
                                                <div className="flex flex-col items-start">
                                                    <h2 className="font-bold text-1xl md:text-1xl text-slate-800">
                                                        Select Team
                                                    </h2>
                                                    {departments.length > 0 && (
                                                        <Select
                                                            onChange={onChangeTeam}
                                                            className="basic-single min-w-[180px] w-auto"
                                                            classNamePrefix="select"
                                                            isSearchable={true}
                                                            defaultValue={teams[0]}
                                                            options={teams}
                                                            name="teams"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {selectedGroup === "employees" && (
                            <div className="w-full px-8">
                                <EmployeeWorkloadTable
                                    data={clientsByEmployee}
                                    className="w-full"
                                />
                            </div>
                        )}

                        {selectedGroup === "teams" && (
                            <div className="w-full px-8">
                                <WorkloadReportByTeams
                                    data={clientsByTeam}
                                    className="w-full"
                                />
                            </div>
                        )}
                        {selectedGroup === "departments" && (
                            <div className="w-full px-8">
                                <WorkloadReportByDepartments
                                    data={clientsByDepartment}
                                    className="w-full"
                                />
                            </div>
                        )}
                    </main>
                </div>
            </div>
        </section>
    );
};
export default WorkloadReport;
