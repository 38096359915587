import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import ClientsByJobs from "../../components/analytics/ClientsByJobs";
import SelectServiceInJobReportCombobox from "../../components/analytics/SelectServiceInJobReportCombobox";
import { getServices } from "../../services/getServices";

const JobsReport = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);

    useEffect(() => {
        const getNewServices = async () => {
            const response = await getServices();
            if (response.success) {
                console.log(response);
                setServices(response.data);
            }
        };
        getNewServices();
    }, []);

    const onChangeService = (newService) => {
        console.log(newService);
        setSelectedServices(newService);
    };
    return (
        <section>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                    <main>
                        <div className="w-full px-4 py-8 mx-auto sm:px-6 lg:px-8 max-w-9xl">
                            {/* Page header */}
                            <div className="mb-8 sm:flex sm:justify-between sm:items-center">
                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0 text">
                                    <h1 className="text-2xl font-bold md:text-3xl text-slate-800">
                                        Pipeline Report{" "}
                                    </h1>
                                    <ul>
                                        {selectedServices.length > 0 &&
                                            selectedServices.map((service) => (
                                                <li>{service.name}</li>
                                            ))}
                                    </ul>
                                </div>
                                <div className="flex flex-col items-center sm:flex-row sm:space-x-2">
                                    <div className="flex flex-col items-start">
                                        <SelectServiceInJobReportCombobox
                                            label="Select Service"
                                            services={services}
                                            selectedService={selectedServices}
                                            onChangeService={onChangeService}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedServices.length > 0 ? (
                            <ClientsByJobs serviceIds={selectedServices} />
                        ) : (
                            <h4 className="m-8 font-bold">
                                Please select a service to see the report.
                            </h4>
                        )}
                    </main>
                </div>
            </div>
        </section>
    );
};
export default JobsReport;
